<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col w-11/12 sm:w-3/5 md:w-1/2 lg:w-3/4 xl:w-3/5 pt-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full py-8 px-12 lg:p-0 lg:w-1/2 text-center">
              <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="65%" height="auto" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="65%" height="auto" alt="Rellopay" class="mx-auto"/>
              <h3 class="lg:hidden text-xl lg:text-2xl pt-6 mb-4">Log in to your account</h3>
              <p class="lg:hidden">Welcome back, please log in to your account.</p>
            </div>

            <div class="vx-col w-full md:w-full lg:w-1/2">
              <div class="pt-2 pb-4 sm:pb-6 px-6 sm:px-10 lg:pt-10 lg:pb-12 lg:px-10 login-tabs-container bg-white">
                <div class="vx-card__title mb-4 lg:mb-0 text-center lg:text-left hidden lg:block">
                  <h3 class="text-xl lg:text-2xl mb-4 text-center">Log in to your account</h3>
                </div>
                <login-jwt></login-jwt>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import LoginJwt from "./LoginJWT.vue";

export default {
  components: {
    LoginJwt
  },
   data(){
    return {
      appName: process.env.VUE_APP_NAME ||"FlipPay"
    }
  }
};
</script>